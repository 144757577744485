// import imgAbout from 'https://res.cloudinary.com/dvdb33uyj/image/upload/v1691681682/Projects/unne/img/experienciaUsuarios/mujer.png';
// import imgAbout2 from 'https://res.cloudinary.com/dvdb33uyj/image/upload/v1691681682/Projects/unne/img/experienciaUsuarios/hombre.png';

/** Main Carousel Data */
export const ImgDataMe = [
  {
    id: 1,
    img: "https://res.cloudinary.com/dvdb33uyj/image/upload/v1691681682/Projects/unne/img/experienciaUsuarios/mujer.png",
  },
  {
    id: 2,
    img: "https://res.cloudinary.com/dvdb33uyj/image/upload/v1691681682/Projects/unne/img/experienciaUsuarios/mujer.png",
  },

]